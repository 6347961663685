import React from 'react'
import { connect } from 'react-redux'

import ChangePage from '../../store/actions/changePage'
import Modal from '../../components/ModalCourses'
import Footer from '../../components/Footer'
import Logo from '../../components/Logo'
import SectionTop from '../../components/SectionTop'
import SectionGrid from '../../components/SectionGrid'
import CardCourse from '../../components/CardCourse'
import WhiteSpace from '../../components/WhiteSpace'

import './styles.scss'

function Training(props){
    return(
        <>
            {/** 
            <Header>
                <InternalLink 
                    to="promocionais"
                    spy={true}
                    smooth={true}
                    offset={-50}
                    duration={500}
                >
                    <li onClick={() => props.changeMenu("menu-open")}>Promocionais</li>
                </InternalLink>
                <InternalLink 
                    to="cursos"
                    spy={true}
                    smooth={true}
                    offset={-50}
                    duration={500}
                >
                    <li onClick={() => props.changeMenu("menu-open")}>Nossos cursos</li>
                </InternalLink>
                <InternalLink 
                    to="breve"
                    spy={true}
                    smooth={true}
                    offset={-50}
                    duration={500}
                >
                    <li onClick={() => props.changeMenu("menu-open")}>Em breve</li>
                </InternalLink>
            </Header>
            */}

            <div className="section-top-training-page">
                {/**  
                <Section id="training-back-button">
                    <Link to="/">
                        <Button className="secondary" onClick={() => Change("home")} > 
                            <BsArrowLeft size={20}/>
                            VOLTAR PARA A HOME
                        </Button>
                    </Link>
                </Section>
                */}

                <SectionTop>
                    <Logo />              
                    <h1>Nossos cursos <br />Presenciais</h1>
                    {/**<h4>Que tal utilizar da metodologia mais moderna, eficaz e rápida para realizar os treinamentos de capacitação de segurança do trabalho?</h4>
                     */}
                </SectionTop>
            </div>

            <SectionGrid
                //title="Nossos cursos Presenciais"
                //subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor "
            >
                <CardCourse 
                    courseName="NR 10"     
                    courseLegend="Treinamento de Reciclagem Bienal em Instalações Elétricas"   
                    coursePrice="140,00"                    
                />
                <CardCourse 
                    courseName="NR 10"     
                    courseLegend=" Formação Segurança em instalações e serviços em eletricidade "   
                    coursePrice="189,00"
                />
                <CardCourse 
                    courseName="NR 35"     
                    courseLegend="Treinamento de Trabalho em Altura"   
                    coursePrice="140,00"
                />
                <CardCourse
                    courseName="NR 33"     
                    courseLegend="Treinamento em espaços confinados - Trabalhadores Autorizados"   
                    coursePrice="140,00"
                />
                <CardCourse
                    courseName="NR 06"     
                    courseLegend="Equipamentos de Proteção Individual"   
                    coursePrice="69,00"
                />
                <CardCourse
                    courseName="NR 18"     
                    courseLegend="Treinamento admissional para trabalho em construção civil"   
                    coursePrice="69,00" 
                />
                <CardCourse
                    courseName="NR 18"     
                    courseLegend="Treinamento periódico para trabalho em construção civil"   
                    coursePrice="69,00"  
                />
                <CardCourse
                    courseName="NR 20"     
                    courseLegend="Curso básico (Instalação Classe I; Atividade específica, pontual e de curta duração)"   
                    coursePrice="59,00" 
                />
                <CardCourse
                    courseName="NR --"     
                    courseLegend="Treinamento para trabalho em áreas classificadas"   
                    coursePrice="55,00" 
                />
                <CardCourse
                    courseName="NR 20"     
                    courseLegend="Curso básico (Instalação Classe III; Atividade específica, pontual e de curta duração)"   
                    coursePrice="99,00"  
                />
                <CardCourse
                    courseName="NR 20"     
                    courseLegend="Curso intermediário (Instalação Classe III; Manutenção e inspeção)"   
                    coursePrice="120,00" 
                />
                <CardCourse
                    courseName="NR 05"     
                    courseLegend="Comissão Interna de Prevenção de Acidentes"   
                    coursePrice="89,00" 
                />
                <CardCourse
                    courseName="NR —"     
                    courseLegend="Proteção Respiratória"   
                    coursePrice="59,00"  
                />
                <CardCourse
                    courseName="NR 12"     
                    courseLegend="Treinamento em Segurança no Trabalho em Máquinas e Equipamentos"   
                    coursePrice="99,00" 
                />
                <CardCourse
                    courseName="NR —"     
                    courseLegend="Noções de Primeiros Socorros"   
                    coursePrice="80,00" 
                />
                <CardCourse
                    courseName="NR 23"     
                    courseLegend="Prevenção e Combate a Incêndio"   
                    coursePrice="70,00"  
                />
                <CardCourse
                    courseName="NR 35"     
                    courseLegend="Permissão de Trabalho Básico"   
                    coursePrice="70,00" 
                />
                <CardCourse
                    courseName="NR 10 + NR 12"     
                    courseLegend="Treinamento em Bloqueio e Etiquetagem"   
                    coursePrice="59,00" 
                />
                <CardCourse
                    courseName="NR 22"     
                    courseLegend="CIPAMIN"   
                    coursePrice="140,00"  
                />
                <CardCourse
                    courseName="NR 22"     
                    courseLegend="Segurança e Saúde Ocupacional na Mineração"   
                    coursePrice="149,00" 
                />
                <CardCourse
                    courseName="NR 06"     
                    courseLegend="Equipamentos de Proteção Hospitalar"   
                    coursePrice="69,00" 
                />
                <CardCourse
                    courseName="NR —"     
                    courseLegend="Treinamento em Direção Preventiva"   
                    coursePrice="70,00" 
                />
                <CardCourse
                    courseName="NR 33"     
                    courseLegend="Treinamento em espaços confinados - Periódico "   
                    coursePrice="140,00" 
               />
                <CardCourse
                    courseName="NR 33"     
                    courseLegend="Treinamento em espaços confinados - Supervisor"   
                    coursePrice="149,00"
                />                            
                <CardCourse
                    courseName="NR 11"     
                    courseLegend="Treinamento para Operadores de Equipamentos com Força Motriz Própria"   
                    coursePrice="-"
                />                
                <CardCourse
                    courseName="NR 10"     
                    courseLegend="Treinamento em Segurança Para Trabalhadores que Intervenham em Instalações Elétricas Energizadas com Alta Tensão no SEP"   
                    coursePrice="189,00"
                />   
                <CardCourse
                    courseName="NR 11"     
                    courseLegend="Capacitação para Movimentação, Armazenagem e Manuseio de Chapas e Rochas Ornamentais - Admissional"   
                    coursePrice="69,00"
                />   
                <CardCourse
                    courseName="NR 11"     
                    courseLegend="Plataforma Elevatória"   
                    coursePrice=""
                />                
                <CardCourse
                    courseName="NR 11"     
                    courseLegend="Operação de Empilhadeira"   
                    coursePrice=""
                />                          

            </SectionGrid>
 

            <WhiteSpace height="120"/>

            <Modal /> 

            <Footer />
        </>
    )
}

function mapStateToProps(state){
    return{
        currentPage: state.currentPage
    }
}

function mapActionCreatorsToProps(dispatch){
    return{
        changePage(newPage){
            const action = ChangePage(newPage)
            dispatch(action)
        }
    }
}

export default connect(
     mapStateToProps,
    mapActionCreatorsToProps
)(Training)

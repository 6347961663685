import { createStore, combineReducers } from 'redux'

const reducers = combineReducers({
    menuState: function(state, action){
        
        switch(action.type){
            case 'NEW_STATE':
                return{
                    menuState: action.payload
                }                
            default:
                return{
                    menuState: 'menu-close'
                }
        }
    },

    modalState: function(state, action1){
        
        switch(action1.type){
            case 'MODAL_CHANGE':
                return{
                    modalState: action1.payload
                } 
            case 'MODAL_CONTENT_CHANGE':   
                return{
                    modalState: 'modal-open'
                }            
            default:
                return{
                    modalState: 'modal-close'
                }
        }
    },

    modalContent: function(state, action2){
        
        switch(action2.type){
            case 'MODAL_CONTENT_CHANGE':
                return{
                    modalContent: action2.payload
                }                
            default:
                return{
                    modalContent: 'contact-form'
                }
        }
    },

    currentPage: function(state, action3){
        switch(action3.type){
            case 'CHANGE_PAGE':
                return{
                    currentPage: action3.payload
                }
            default:
                return{
                    currentPage: "home"
                }
        }
    }
})

export default function storeConfig(){
    return createStore(reducers)
}


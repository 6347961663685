import React from 'react'

export default function WhiteSpace(props){
    return(
        <div 
            className="white-space"
            style={{
            width: `100%`,
            height: `${props.height}px`
        }} />
    )
}
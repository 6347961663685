import React from 'react'

import Section from '../Section'

import './styles.scss'

export default function SectionTop(props){
    return(
        <Section className="section-top">
            {props.children}
        </Section>
    )
}
import React from 'react'

import './styles.scss'

export default function CardServices(props){
    return(
        <div className="card-service">
            <img src={props.src} alt={`Ícone de ${props.text}`}/>
            <p>{props.text}</p>
        </div>
    )
}
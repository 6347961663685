import React from 'react'
import { connect } from 'react-redux'

import ChangePage from '../../store/actions/changePage'

import Carousel from '../../components/Carousel'

import Modal from '../../components/ModalCourses'
import Footer from '../../components/Footer'

import Logo from '../../components/Logo'
import Section from '../../components/Section'
import SectionTop from '../../components/SectionTop'
import SectionGrid from '../../components/SectionGrid'
import CardCourse from '../../components/CardCourse'
import WhiteSpace from '../../components/WhiteSpace'

import './styles.scss'

function Training(props){
    return(
        <>
            {/** 
            <Header>
                <InternalLink 
                    to="promocionais"
                    spy={true}
                    smooth={true}
                    offset={-50}
                    duration={500}
                >
                    <li onClick={() => props.changeMenu("menu-open")}>Promocionais</li>
                </InternalLink>
                <InternalLink 
                    to="cursos"
                    spy={true}
                    smooth={true}
                    offset={-50}
                    duration={500}
                >
                    <li onClick={() => props.changeMenu("menu-open")}>Nossos cursos</li>
                </InternalLink>
                <InternalLink 
                    to="breve"
                    spy={true}
                    smooth={true}
                    offset={-50}
                    duration={500}
                >
                    <li onClick={() => props.changeMenu("menu-open")}>Em breve</li>
                </InternalLink>
            </Header>
            */}

            <div className="section-top-training-page">
                {/**  
                <Section id="training-back-button">
                    <Link to="/">
                        <Button className="secondary" onClick={() => Change("home")} > 
                            <BsArrowLeft size={20}/>
                            VOLTAR PARA A HOME
                        </Button>
                    </Link>
                </Section>
                */}

                <SectionTop>
                    <Logo />              
                    <h1>Plataforma digital <br /> <span>&</span> interativa</h1>
                    <h4>Que tal utilizar da metodologia mais moderna, eficaz e rápida para realizar os treinamentos de capacitação de segurança do trabalho?</h4>
                </SectionTop>
            </div>        

            <SectionGrid
                id="section-grid-desktop"
                title="Planos promocionais para a sua empresa!"
            >
                <CardCourse 
                    type="plan"
                    tagNumber="30"
                    planName="NR10 - 16horas"
                    planLegend="Reciclagem"
                    planPriceOff="5.070,00"
                    planPrice="3.900,00"  
                    code="d13bb584-7e92-4618-9935-fd34c6b6332c"                  
                />                 

                <CardCourse 
                    type="plan"
                    tagNumber="20"
                    planName="NR35 - 8horas"
                    planLegend="Trabalhos em altura"
                    planPriceOff="4.100,00"
                    planPrice="3.450,00"      
                    code="33023aa4-eda7-4aba-a33d-77c76cb9377f"                
                />                

                <CardCourse 
                    type="plan"
                    tagNumber="30"
                    planName="NR33 - 16horas"
                    planLegend="Segurança em espaços confinados"
                    planPriceOff="4.800,00"
                    planPrice="3.450,00"
                    code="a2c7ab67-b695-4050-92b7-13a3ec0f4eae"   
                />

            </SectionGrid>

            <Section id="section-grid-carousel"> 
                <Carousel>
                    <div style={{padding: `10px`}}>
                        <CardCourse 
                            type="plan"
                            tagNumber="30"
                            planName="NR10 - 16horas"
                            planLegend="Reciclagem"
                            planPriceOff="5.070,00"
                            planPrice="3.900,00"
                            code="d13bb584-7e92-4618-9935-fd34c6b6332c"        
                        />
                    </div>
                    <div style={{padding: `10px`}}>
                        <CardCourse 
                            type="plan"
                            tagNumber="20"
                            planName="NR35 - 8horas"
                            planLegend="Trabalhos em altura"
                            planPriceOff="4.100,00"
                            planPrice="3.450,00"
                            code="33023aa4-eda7-4aba-a33d-77c76cb9377f"    
                        />
                    </div>
                    <div style={{padding: `10px`}}>
                        <CardCourse 
                            type="plan"
                            tagNumber="30"
                            planName="NR33 - 16horas"
                            planLegend="Segurança em espaços confinados"
                            planPriceOff="4.800,00"
                            planPrice="3.450,00"
                            code="a2c7ab67-b695-4050-92b7-13a3ec0f4eae"   
                        />
                    </div>
                </Carousel>
            </Section>

            <SectionGrid
                title="Nossos cursos (online)"
                //subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor "
            >
                <CardCourse 
                    type="online"
                    courseName="NR 10 - 16horas"     
                    courseLegend="Treinamento de Reciclagem Bienal em Instalações Elétricas"   
                    coursePrice="140,00" 
                    code="9e90bd76-b2db-43bc-9aee-bf12689dcf7f" 
                />
                <CardCourse 
                    type="online"
                    courseName="NR 10 - 40horas"     
                    courseLegend=" Formação Segurança em instalações e serviços em eletricidade "   
                    coursePrice="189,00"  
                    code="1f236932-26d8-46c3-a7f8-bf8fca5032df"
                />
                <CardCourse 
                    type="online"
                    courseName="NR 35 - 8horas"     
                    courseLegend="Treinamento de Trabalho em Altura"   
                    coursePrice="140,00"
                    code="48e22293-6483-43e6-a64c-6f69d71e4b6e" 
                />
                <CardCourse
                    type="online" 
                    courseName="NR 33 - 16horas"     
                    courseLegend="Treinamento em espaços confinados - Trabalhadores Autorizados"   
                    coursePrice="140,00"  
                    code="cc0558fe-0560-4f02-9458-599f0ad0fafd"
                />
                <CardCourse
                    type="online" 
                    courseName="NR 06 - 2horas"     
                    courseLegend="Equipamentos de Proteção Individual"   
                    coursePrice="69,00" 
                    code="a91ca434-588d-45d5-9f5c-0f5c5e9285b2"
                />
                <CardCourse
                    type="online" 
                    courseName="NR 18 - 6horas"     
                    courseLegend="Treinamento admissional para trabalho em construção civil"   
                    coursePrice="69,00" 
                    code="b1a61734-b39b-48c2-8033-024ca1b791cc"
                />
                <CardCourse
                    type="online" 
                    courseName="NR 18 - 6horas"     
                    courseLegend="Treinamento periódico para trabalho em construção civil"   
                    coursePrice="69,00"  
                    code="eadab5ed-55d9-4c48-b7d2-7ec641b87349"
                />
                <CardCourse
                    type="online" 
                    courseName="NR 20 - 4horas"     
                    courseLegend="Curso básico (Instalação Classe I; Atividade específica, pontual e de curta duração)"   
                    coursePrice="59,00" 
                    code="555961f7-bb0b-44ec-bc29-d197ccf81b31"
                />
                <CardCourse
                    type="online" 
                    courseName="NR 10 - 2horas"     
                    courseLegend="Treinamento para trabalho em áreas classificadas"   
                    coursePrice="55,00" 
                    code="ea34459a-df3f-4d2a-b62d-4d7bd8438d33"
                />
                <CardCourse
                    type="online" 
                    courseName="NR 20 - 8horas"     
                    courseLegend="Curso básico (Instalação Classe III; Atividade específica, pontual e de curta duração)"   
                    coursePrice="99,00"  
                    code="3ee3ef45-5dba-4eb7-a0bb-dcf7fb851639"
                />
                <CardCourse
                    type="online" 
                    courseName="NR 20 - 16horas"     
                    courseLegend="Curso intermediário (Instalação Classe III; Manutenção e inspeção)"   
                    coursePrice="120,00" 
                    code="03caaca9-3c56-4a7a-a991-5d9a8fd92dd2"
                />
                <CardCourse
                    type="online" 
                    courseName="NR 05 - 20horas"     
                    courseLegend="Comissão Interna de Prevenção de Acidentes"   
                    coursePrice="89,00" 
                    code="b0a79081-4563-4e05-a893-eb830bfd2424"
                />
                <CardCourse
                    type="online" 
                    courseName="2horas"     
                    courseLegend="Proteção Respiratória"   
                    coursePrice="59,00"  
                    code="5b2fb51d-d356-49ae-9879-5e6bca0970e0"
                />
                <CardCourse
                    type="online" 
                    courseName="NR 12 - 4horas"     
                    courseLegend="Treinamento em Segurança no Trabalho em Máquinas e Equipamentos"   
                    coursePrice="99,00" 
                    code="c6d60c44-5b40-42aa-bfad-84bb388d9459"
                />
                <CardCourse
                    type="online" 
                    courseName="2horas"     
                    courseLegend="Noções de Primeiros Socorros"   
                    coursePrice="80,00" 
                    code="91ae89e6-3b07-49af-b33a-a4bacbfc8d6c"
                />
                <CardCourse
                    type="online" 
                    courseName="NR 23 - 8horas"     
                    courseLegend="Prevenção e Combate a Incêndio"   
                    coursePrice="70,00"  
                    code="c4ca2f22-ca3e-460a-9306-c23787e34098"
                />
                <CardCourse
                    type="online" 
                    courseName="NR 35 - 4horas"     
                    courseLegend="Permissão de Trabalho Básico"   
                    coursePrice="70,00" 
                    code="76750bfc-c7c4-411c-8869-0f4e6c71d342"
                />
                <CardCourse
                    type="online" 
                    courseName="NR 10 + NR 12 - 4horas"     
                    courseLegend="Treinamento em Bloqueio e Etiquetagem"   
                    coursePrice="59,00" 
                    code="68903daa-eeeb-4625-b539-c392df626c30"
                />
                <CardCourse
                    type="online" 
                    courseName="NR 22 - 40horas"     
                    courseLegend="CIPAMIN"   
                    coursePrice="140,00"  
                    code="0249de6b-ce16-4431-8e70-d77be91f522f"
                />
                <CardCourse
                    type="online" 
                    courseName="NR 22 - 24horas"     
                    courseLegend="Segurança e Saúde Ocupacional na Mineração"   
                    coursePrice="149,00" 
                    code="3101184e-a20f-43bb-8e23-3cf421cf89c5"
                />
                <CardCourse
                    type="online" 
                    courseName="NR 06 - 2horas"     
                    courseLegend="Equipamentos de Proteção Hospitalar"   
                    coursePrice="69,00" 
                    code="ec715fc1-f158-41ff-a377-2209ee33a253"
                />
                <CardCourse
                    type="online" 
                    courseName="4horas"     
                    courseLegend="Treinamento em Direção Preventiva"   
                    coursePrice="70,00" 
                    code="29a7de54-0573-4cad-bbc6-18a3886e2c40"
                />
                <CardCourse
                    type="online" 
                    courseName="NR 33 - 8horas"     
                    courseLegend="Treinamento em espaços confinados - Periódico"   
                    coursePrice="140,00" 
                    code="8d00a7ae-38f3-42e5-b49d-9f946a65bfde"
                />
                <CardCourse
                    type="online" 
                    soon= {true}
                    courseName="NR 33 - 40horas"     
                    courseLegend="Treinamento em espaços confinados - Supervisor"   
                    coursePrice="149,00" 
                    code=""
                />                            
                <CardCourse
                    type="online" 
                    soon= {true}
                    courseName="NR 11"     
                    courseLegend="Treinamento para Operadores de Equipamentos com Força Motriz Própria"   
                    coursePrice="-" 
                    code=""
                />                
                <CardCourse
                    type="online" 
                    soon= {true}
                    courseName="NR 10"     
                    courseLegend="Treinamento em Segurança Para Trabalhadores que Intervenham em Instalações Elétricas Energizadas com Alta Tensão no SEP"   
                    coursePrice="189,00" 
                    code=""
                />   
                <CardCourse
                    type="online" 
                    soon= {true}
                    courseName="NR 11"     
                    courseLegend="Capacitação para Movimentação, Armazenagem e Manuseio de Chapas e Rochas Ornamentais - Admissional"   
                    coursePrice="69,00" 
                    code=""
                />   
                <CardCourse
                    type="online" 
                    soon= {true}
                    courseName="NR 11"     
                    courseLegend="Plataforma Elevatória"   
                    coursePrice="" 
                    code=""
                />                
                <CardCourse
                    type="online" 
                    soon= {true}
                    courseName="NR 11"     
                    courseLegend="Operação de Empilhadeira"   
                    coursePrice="" 
                    code=""
                />              

            </SectionGrid>
 

            <WhiteSpace height="120"/>

            <Modal /> 

            <Footer />
        </>
    )
}

function mapStateToProps(state){
    return{
        currentPage: state.currentPage
    }
}

function mapActionCreatorsToProps(dispatch){
    return{
        changePage(newPage){
            const action = ChangePage(newPage)
            dispatch(action)
        }
    }
}

export default connect(
     mapStateToProps,
    mapActionCreatorsToProps
)(Training)

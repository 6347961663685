import React from 'react'

import Section from '../Section'

import './styles.scss'

export default function SectionGrid(props){
    return(
        <>
            <Section className="section-services" >
                <h3>{props.title}</h3>
                <p>{props.subtitle} </p>                
            </Section>
            <Section className={`section-grid-services ${props.className}`} id={props.id}>  
                {props.children}
            </Section>
        </>
    )
}
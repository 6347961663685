import React from 'react'
import { Link } from 'react-router-dom'

import Footer from '../../components/Footer'

import Logo from '../../components/Logo'
import SectionTop from '../../components/SectionTop'
import Button from '../../components/Button'
import WhiteSpace from '../../components/WhiteSpace'

export default function PageNotFound(){

    return(
        <>
            <SectionTop>
                <Logo />
                <h1>Ops... erro <span>404</span></h1>
                <h4>Desculpe, a página que você está tentando acessar não foi encontrada.</h4>

                <WhiteSpace height="60" />
                <Link  to="/">
                    <Button className="primary">Voltar para a home</Button>
                </Link>
                <WhiteSpace height="66" />
            </SectionTop>

            <Footer />
        </>
    )
} 

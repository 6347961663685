import React from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'

import ChangeState from '../../store/actions/changeMenuMobile'

import LogoMobile from '../Logo/logo-mobile'
import WhiteSpace from '../../components/WhiteSpace'

import './styles.scss'

function Nav(props){
    const { menuState } = props.menuState

    return(
        <nav className={`nav nav-${menuState}`}>
            <ul>                      
                <li className="li-logo-mobile">              
                    <LogoMobile />                                     
                </li>               
                    

                <WhiteSpace height="50" />

                {/**                
                <Link to="/">
                    <li 
                        onClick={() => Change("home")}
                        id={`home-${currentPage}`} 
                    >
                        Home
                    </li>
                </Link>
                <Link to="/em-construção">
                    <li 
                        onClick={() => Change("about")}
                        id={`about-${currentPage}`}
                    >
                        Quem somos?
                    </li>
                </Link>                        
                <Link to="/consultoria">
                    <li 
                        onClick={() => Change("consulting")}
                        id={`consulting-${currentPage}`}
                    >
                        Consultoria
                    </li>
                </Link>
                <Link to="/capacitação">
                    <li 
                        onClick={() => Change("training")}
                        id={`training-${currentPage}`}
                    >
                        Capacitação
                    </li>
                </Link>
                <Link to="/contato">
                    <li 
                        onClick={() => Change("contact")} 
                        id={`contact-${currentPage}`}
                    >
                        Contato
                    </li>
                </Link>
                */}

                
                 
                <li onClick={() => props.changeMenu("menu-open")}>
                    <NavLink exact to="/" activeStyle={{color: `#1491FF`}} activeClassName="actived">                    
                        Home                    
                    </NavLink>
                </li>
                {/** 
                <li onClick={() => props.changeMenu("menu-open")}>
                    <NavLink to="/em-construção" activeStyle={{color: `#1491FF`}} activeClassName="actived">
                        Quem somos?                    
                    </NavLink>   
                </li>  
                */}                   
                <li onClick={() => props.changeMenu("menu-open")}>
                    <NavLink to="/consultoria" activeStyle={{color: `#1491FF`}} activeClassName="actived">
                        Consultoria                    
                    </NavLink>
                </li>
                <li onClick={() => props.changeMenu("menu-open")}>
                    <NavLink to="/capacitação-online" activeStyle={{color: `#1491FF`}} activeClassName="actived">
                        Capacitação Online                  
                    </NavLink>
                </li>

                <li onClick={() => props.changeMenu("menu-open")}>
                    <NavLink to="/capacitação-presencial" activeStyle={{color: `#1491FF`}} activeClassName="actived">
                        Capacitação Presencial                 
                    </NavLink>
                </li>

                <li onClick={() => props.changeMenu("menu-open")}>
                    <NavLink to="/contato" activeStyle={{color: `#1491FF`}} activeClassName="actived">
                        Contato                        
                    </NavLink>
                </li>
                
                {/** 
                <NavLink to="/" activeStyle={{color: `#1491FF`}} >    
                    <li>                                    
                        Home                  
                    </li>
                </NavLink>
                
                <NavLink to="/em-construção" activeStyle={{color: `#1491FF`}}>
                    <li>
                        Quem somos?   
                    </li>                 
                </NavLink>   
                                    
            
                <NavLink to="/consultoria" activeStyle={{color: `#1491FF`}}>
                    <li>
                        Consultoria   
                    </li>                 
                </NavLink>
            
            
                <NavLink to="/capacitação" activeStyle={{color: `#1491FF`}}>
                    <li>
                        Capacitação    
                    </li>                
                </NavLink>
            
            
                <NavLink to="/contato" activeStyle={{color: `#1491FF`}}>
                    <li>
                        Contato   
                    </li>                     
                </NavLink>
                */}
                

            </ul>
        </nav>
    )
}

function mapStateToProps(state){
    return{
        menuState: state.menuState,
    }
}

function mapActionCreatorsToProps(dispatch){
    return{
        changeMenu(currentState){
            const action = ChangeState(currentState)
            dispatch(action)
        }
    }
}

export default connect(
    mapStateToProps,
    mapActionCreatorsToProps
)(Nav)
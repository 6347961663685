import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import ChangePage from '../../store/actions/changePage'

import Footer from '../../components/Footer'

import Logo from '../../components/Logo'
import SectionTop from '../../components/SectionTop'
import Button from '../../components/Button'
import WhiteSpace from '../../components/WhiteSpace'

function EmailSuccess(props){

    function Change(newPage){
        props.changePage(newPage)
    }

    return(
        <>
            {/** 
            <Header>
                <Link to="/"><li>Home</li></Link>
                <li>Quem somos?</li>
                <li>Serviços</li>
                <li>Treinamentos</li>
            </Header> 
            */}

            <SectionTop>
                <Logo />
                <h1>Contato enviado com <span>sucesso!</span></h1>
                <h4>Em breve entraremos em contato!</h4>

                <WhiteSpace height="60" />
                <Link  to="/">
                    <Button className="primary" onClick={() => Change("home")}>Voltar para a home</Button>
                </Link>
                <WhiteSpace height="66" />
            </SectionTop>

            <Footer />
        </>
    )
} 

function mapStateToProps(state){
    return{
        currentPage: state.currentPage
    }
}

function mapActionCreatorsToProps(dispatch){
    return{
        changePage(newPage){
            const action = ChangePage(newPage)
            dispatch(action)
        }
    }
}

export default connect(
     mapStateToProps,
    mapActionCreatorsToProps
)(EmailSuccess)
import React, { useState} from 'react'
import { Link, useHistory } from 'react-router-dom'
import emailjs from 'emailjs-com';
import { connect } from 'react-redux'

import ChangeModalContent from '../../store/actions/changeModalContent'

import InputGroup from './InputGroup'
import Button from '../Button'

import './styles.scss'

function Form(props){
    const history = useHistory()

    const modal = props   

    const [ name, setName] = useState('')
    const [ company, setCompany] = useState('')
    const [ email, setEmail] = useState('')
    const [ phone, setPhone] = useState('')

    const [ nameFeedback, setNameFeedback] = useState('')
    const [ companyFeedback, setCompanyFeedback] = useState('')
    const [ emailFeedback, setEmailFeedback] = useState('')
    const [ phoneFeedback, setPhoneFeedback] = useState('')

    function Reset(){
        setName('')
        setCompany('')
        setEmail('')
        setPhone('')
        setNameFeedback('')
        setCompanyFeedback('')
        setEmailFeedback('')
        setPhoneFeedback('')
    }

    function validation(){
        let validate = true
        
        if(name === ""){
            validate = false
            setNameFeedback("(Nome obrigatório)")            
        }
        if(company === ""){
            validate = false
            setCompanyFeedback("(Nome da empresa obrigatório)")
        }
        if(email.search("@") === -1){
            validate = false
            setEmailFeedback("(Email precisa conter @)")
        }
        if(email === ""){
            validate = false
            setEmailFeedback("(Email obrigatório)")
        }
        if(phone === ""){
            validate = false
            setPhoneFeedback("(Telefone obrigatório)")
        }
        if(phone.length < 8){
            validate = false
            setPhoneFeedback("(Mínimo de 8 digitos)")
        }  
        return validate
    }

    function handleTelephoneChange(e){
        if (Number(e)) {
            setPhone(e)
        }else{
            if((e) === '' ){
                setPhone('')
            }
        }
    };

    

    async function handleSubmit(e){
        e.preventDefault()

        if(validation(e)){
            console.log(e)
            await emailjs.sendForm('gmail', 'site_creta_css', e.target, 'user_BfWbd4fGiwLwkH8v3UId9')
            .then(()=>{                    
                //modal.changeModalContent("contact-success")            
                if(modal.modal.modalState === "modal-open"){
                    modal.changeModalContent("contact-success") 
                }else{
                    history.push('/contato/email-enviado-com-sucesso')     
                }    
                //history.push('/contato/email-enviado-com-sucesso')      
            }).catch(()=>{
                alert('Não foi possivel enviar, tente novamente mais tarde.')
            })
        }
    }

    return(
        <form className="contact-form" onSubmit={handleSubmit}>
            <h5>Contato</h5>

            <InputGroup 
                label="Nome:"
                feedback={nameFeedback}
                id="name"
                type="text"
                placeholder="Emanuel"
                onChange={e=>setName(e.target.value)}
                onFocus={()=>setNameFeedback('')}
                value={name}
            />
            <InputGroup 
                label="Empresa:"
                feedback={companyFeedback}
                id="company"
                type="text"
                placeholder="Nome Social"
                onChange={e=>setCompany(e.target.value)}
                onFocus={()=>setCompanyFeedback('')}
                value={company}
            />
            <InputGroup 
                label="E-mail:"
                feedback={emailFeedback}
                id="email"
                type="email"
                placeholder="empresa@gmail.coml"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                onChange={e=>setEmail(e.target.value)}
                onFocus={()=>setEmailFeedback('')}
                value={email}
            />
            <InputGroup 
                label="Telefone:"
                feedback={phoneFeedback}
                id="phone"
                type="tel"
                placeholder="(00)0000-0000"
                onChange={e=>handleTelephoneChange(e.target.value)}
                onFocus={()=>setPhoneFeedback('')}
                value={phone}
            />

            <div className="button-actions">           
                

                {props.buttonBack === true 
                    ? <> 
                        <Button 
                            type="submit" 
                            className="primary"
                        >
                            Enviar
                        </Button> 

                        <Link to ="/">
                            <Button>Voltar</Button> 
                        </Link> 
                        </>

                    : <Button 
                        type="submit"                        
                    >Enviar</Button> 
                }

                <div onClick={Reset}>{props.children}</div>
            </div>
        </form>
    )
}

function mapStateToProps(state){
    return{
        modal: state.modalState,
        content: state.modalContent
    }
}

function mapActionCreatorsToProps(dispatch){
    return{
        /** 
        changeModal(newModal){
            const action = ChangeModal(newModal)
            dispatch(action)
        },*/
        changeModalContent(newModalContent){
            const action = ChangeModalContent(newModalContent)
            dispatch(action)
        },
    }
}

export default connect(
     mapStateToProps,
    mapActionCreatorsToProps
)(Form)
import React from 'react'
import { Link } from 'react-router-dom'

import { connect } from 'react-redux'
import ChangeState from '../../store/actions/changeMenuMobile'
import ChangePage from '../../store/actions/changePage'

import LogoSmall from '../Logo/logo-small'
import Section from '../Section'
import Nav from '../Nav'
import SocialMedia from '../SocialMedia'
import MenuMobileIcon from '../MenuMobileIcon'
import WhiteSpace from '../WhiteSpace'

import './styles.scss'

function Header(props){
    const { menuState } = props.menuState

    function changeCurrentPage(newPage){
        props.changePage(newPage)
        props.changeMenu("menu-open")        
    }

    return(
        <>
            <Section className={`header header-${menuState}`}>     
                <Link to="/">
                    <LogoSmall className="logo-small" onClick={() => changeCurrentPage("home")}/>    
                </Link>
                
                <Nav links={props.children} />
                <SocialMedia />
                <MenuMobileIcon className={menuState} />
            </Section>

            <WhiteSpace height="72" />
        </>
    )
}

function mapStateToProps(state){
    return{
        menuState: state.menuState,
        currentPage: state.currentPage
    }
}

function mapActionCreatorsToProps(dispatch){
    return{
        changeMenu(currentState){
            const action = ChangeState(currentState)
            dispatch(action)
        },
        changePage(newPage){
            const action = ChangePage(newPage)
            dispatch(action)
        }
    }
}

export default connect(
    mapStateToProps, 
    mapActionCreatorsToProps
)(Header)
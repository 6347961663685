import React from 'react'
import { connect } from 'react-redux'

import ChangeModal from '../../store/actions/changeModal'

import './styles.scss'
import ImgGroup from '../../assets/images/group.png'
import Button from '../Button'

function CardService(props){
       
    if(props.type==="plan"){
        return(
            <>
            {/**  Backup
            <div className="card-plan" >

                <div className="tag-discount">
                    <p className="number">{props.tagNumber}%</p>
                    <p className="de">de</p>
                    <p className="desconto">desconto</p>                
                </div>
                <div className="details">
                    <img src={ImgGroup} alt="Ícone de grupo de pessoas"/>      
                    <p className="plan-name">{props.planName}</p>     
                    <p className="plan-legend">{props.planLegend}</p>    
                    <p className="plan-details">Certificados de acordo com a NT 54/2018/DSST/SIT/MTE.</p>    
                    <p className="plan-collab">30 colaboradores</p>    
                    <p className="plan-price-off">R$ {props.planPriceOff}</p>    
                    <p className="plan-price">R$ {props.planPrice}</p>                                          
                    <Button 
                        className="third" 
                        onClick={() => props.changeModal("modal-open")}
                    >
                        Reserve Já
                    </Button> 
                    <p className="plan-comments">A licença de todo ou qualquer curso expira em 4 meses após a compra do pacote</p>     
                </div>
            </div>*/}
            
            <div className="card-plan" >
                <div className="tag-discount">
                    <p className="number">{props.tagNumber}%</p>
                    <p className="de">de</p>
                    <p className="desconto">desconto</p>                
                </div>
                <div className="details">
                    <img src={ImgGroup} alt="Ícone de grupo de pessoas"/>      
                    <p className="plan-name">{props.planName}</p>     
                    <p className="plan-legend">{props.planLegend}</p>    
                    <p className="plan-details">Certificados de acordo com a NT 54/2018/DSST/SIT/MTE.</p>    
                    <p className="plan-collab">30 colaboradores</p>    
                    <p className="plan-price-off">R$ {props.planPriceOff}</p>    
                    <p className="plan-price">R$ {props.planPrice}</p>   

                    <form class="form-link-compra" method='post' action='https://cieloecommerce.cielo.com.br/transactionalvnext/order/buynow' target='blank'>
                        <input type='hidden' name='id' value={props.code} />                                           
                        <Button className="third" type="submit">
                            Reserve Já
                        </Button>  
                    </form> 
                    <p className="plan-comments">A licença de todo ou qualquer curso expira em 4 meses após a compra do pacote</p>     
                </div>
            </div>
            
            </>
        )
    }else{    
        const CardCoursesContent = 
            <>
                { 
                    props.soon === true ? 
                    <div className="tag-soon">
                        EM BREVE
                    </div>                    
                    : null
                }                
                <div className={props.soon ? "point-soon" : "point"} />            
                <div className="details">     
                    <p className="course-name">{props.courseName}</p>     
                    <p className="course-legend">{props.courseLegend}</p> 
                    {props.type ==="online" ?   
                        <p className="course-price">R$ {props.coursePrice}</p>   
                        :
                        <p className="course-price">Saiba Mais</p>
                    }  
                    <p className="course-comments">Este valor se refere ao curso individual</p>   
                </div>
            </>

        return(            
            props.type ==="online" ?           
                <form method='post' action='https://cieloecommerce.cielo.com.br/transactionalvnext/order/buynow' target='blank'>
                    <input type='hidden' name='id' value={props.code} />
                    {props.code === "" ? 
                        <div className="card-course">
                            {CardCoursesContent}
                        </div> 
                        :
                        <button type="submit" className="button-link-compra">
                            <div className="card-course">
                                {CardCoursesContent}
                            </div>  
                        </button>   
                        }
                                    
                </form>                   
                :
                <div className="card-course" onClick={() => props.changeModal("modal-open")}>
                    {CardCoursesContent}
                </div> 

            
        )
    }    
}


function mapStateToProps(state){
    return{
        modal: state.modalState
    }
}

function mapActionCreatorsToProps(dispatch){
    return{
        changeModal(newModal){
            //console.log(newModal)
            const action = ChangeModal(newModal)
            dispatch(action)
        }
    }
}

export default connect(
    mapStateToProps,
    mapActionCreatorsToProps
)(CardService)

import React from 'react'
import { connect } from 'react-redux'

import ChangePage from '../../store/actions/changePage'

import Footer from '../../components/Footer'
//import { BsArrowLeft } from 'react-icons/bs'

import Section from '../../components/Section'
import WhiteSpace from '../../components/WhiteSpace'
//import Button from '../../components/Button'
import SectionContactFooter from '../../components/SectionContactFooter'
import SectionGrid from '../../components/SectionGrid'
import CardServices from '../../components/CardServices'

import ImgPPRA from '../../assets/images/ppra.png'
import ImgBreath from '../../assets/images/breath.png'
import ImgHear from '../../assets/images/hear.png'

import ImgPCMSO from '../../assets/images/pcmso.png'
import ImgErgonomic from '../../assets/images/ergonomic.png'
import ImgEmergency from '../../assets/images/emergency.png'

import ImgIndustry from '../../assets/images/industry.png'
import ImgPGR from '../../assets/images/pgr.png'
import ImgACVB from '../../assets/images/acvb.png'

import './styles.scss'

function Consulting(props){    
    return(
        <>
            {/** 
            <Header>
                <li onClick={() => { scroll.scrollToTop(); props.changeMenu("menu-open")}}>Segurança do trabalho</li>
                <InternalLink 
                    to="treinamentos"
                    spy={true}
                    smooth={true}
                    offset={-50}
                    duration={500}
                >
                    <li onClick={() => props.changeMenu("menu-open")}>Treinamentos</li>
                </InternalLink>
                <InternalLink 
                    to="consultoria"
                    spy={true}
                    smooth={true}
                    offset={-50}
                    duration={500}
                >
                    <li onClick={() => props.changeMenu("menu-open")}>Nossa Consultoria</li>
                </InternalLink>
                <InternalLink 
                    to="contato"
                    spy={true}
                    smooth={true}
                    offset={-50}
                    duration={500}
                >
                    <li onClick={() => props.changeMenu("menu-open")}>Entre em contato</li>
                </InternalLink>
            </Header> 
            */}

            <Section>
                <WhiteSpace height={40}/>
                <h3>Segurança do trabalho</h3>
                <WhiteSpace height={25}/>
                <p>
                    A segurança no trabalho deve ser exaltada todos os dias pelas empresas 
                    e seus funcionários por sua importância. Em um mercado muitas vezes 
                    incerto e inseguro, os dirigentes das companhias precisam buscar formas 
                    de se destacar continuamente e, para crescer de forma sustentável, 
                    é preciso estar atento também ao ambiente interno da organização.
                </p>
                <WhiteSpace height={20}/>
            </Section>

            <Section id="section-consulting-top">
                {/** 
                <Link to="/">
                    <Button className="secondary" onClick={() => Change("home")} > 
                        <BsArrowLeft size={20}/>
                        VOLTAR PARA A HOME
                    </Button>
                </Link>
                 */}

                <div className="img-safety" alt="Segurança no Trabalho - imagem de uma tecla azul escrita Safety Check" />
            </Section>

            <Section id="section-safety">               
                <p> 
                    Uma empresa que zela pela segurança dos seus funcionários é uma instituição 
                    que naturalmente apresenta maior responsabilidade social, afinal, ela 
                    sabe que seus profissionais necessitam estar amparados e atuando sob 
                    boas condições, para que possam cumprir suas tarefas de forma satisfatória.
                </p>
                <p>
                    Ao fazer isso, é proporcionado um clima organizacional mais favorável e seguro, 
                    o que reflete em resultados positivos, tanto internos quanto externos.
                </p>                
                <p>
                    Além disso, uma empresa que é percebida como tendo responsabilidade social, 
                    carrega junto uma das premissas para a sustentabilidade e tem sua imagem 
                    impactada positivamente. São benefícios menos tangíveis, mas de grande importância.
                </p>
            </Section>


            <span name="treinamentos" href="#"/>
            <Section id="section-training">
                <div id="man-working" alt="Funcionário trabalhando com segurança"/>

                <div>
                    <h3>Treinamento</h3>
                    <p>
                        Um dos passos fundamentais para implantar a segurança do trabalho em qualquer 
                        empresa é envolver os funcionários nesse objetivo. Para isso, a Cretta CCS 
                        oferece treinamentos promovendo reforço e atualização do conhecimento.
                    </p>
                    <p>
                        Além dos treinamentos que capacitam os funcionários no uso de equipamentos 
                        e no desenvolvimento de suas atividades, também é importante capacitar a 
                        área de gestão da empresa sobre como administrar esses processos.
                    </p>
                </div>
                </Section>

            <span name="consultoria" href="#"/>
            <SectionGrid 
                title="Nossa Consultoria" 
            >
                <CardServices
                    src={ImgPPRA}
                    text="PPRA – Programa de Prevenção de Riscos Ambientais"
                />
                <CardServices
                    src={ImgBreath}
                    text="Programa de Proteção Respiratória"
                />
                <CardServices
                    src={ImgHear}
                    text="Programa de Proteção Auditiva"
                />
                <CardServices
                    src={ImgPCMSO}
                    text="PCMSO - Programa de Controle Médico de Saúde Ocupacional"
                />
                <CardServices
                    src={ImgErgonomic}
                    text="Análise Ergonômica do Trabalho"
                />
                <CardServices
                    src={ImgEmergency}
                    text="Plano de Atendimento e Resposta à Emergências"
                />
                <CardServices
                    src={ImgIndustry}
                    text="Programa de Condições e Meio Ambiente de Trabalho na Indústria da Construção Civil"
                />
                <CardServices
                    src={ImgPGR}
                    text="PGR – Programa de Gerenciamento de Resíduos"
                />
                <CardServices
                    src={ImgACVB}
                    text="ACVB – Atestado de Vistoria do Corpo de Bombeiros"
                />          
            </SectionGrid>


            <span name="contato" href="#" />
            <SectionContactFooter />

            <Footer />

        </>
    )
}

function mapStateToProps(state){
    return{
        currentPage: state.currentPage
    }
}

function mapActionCreatorsToProps(dispatch){
    return{
        changePage(newPage){
            const action = ChangePage(newPage)
            dispatch(action)
        }
    }
}

export default connect(
     mapStateToProps,
    mapActionCreatorsToProps
)(Consulting)

import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import ChangePage from '../../store/actions/changePage'

import Logo from '../../components/Logo'
import SectionTop from '../../components/SectionTop'
import Section from '../../components/Section'
import Button from '../../components/Button'
import CardServices from '../../components/CardServices'
import SectionContactFooter from '../../components/SectionContactFooter'
import SectionGrid from '../../components/SectionGrid'

import Footer from '../../components/Footer'

import ImgClients from '../../assets/images/clients@2x.png'
import ImgLifeline from '../../assets/images/lifeline.png'
import ImgAlarms from '../../assets/images/alarms.png'
import ImgRisk from '../../assets/images/risk.png'
import ImgSpecialized from '../../assets/images/specialized.png'
import ImgEnvironment from '../../assets/images/environment.png'
import ImgSSMA from '../../assets/images/ssma.png'
import ImgRiskMagenement from '../../assets/images/risk-management.png'
import ImgProjects from '../../assets/images/projects.png'
import ImgMedicalExams from '../../assets/images/medical-exams.png'


import './styles.scss'

function Home(props){

    function Change(newPage){
        props.changePage(newPage)
    }

    return(
        <>       
            {/** 
            <Header>
                <li onClick={() => { scroll.scrollToTop(); props.changeMenu("menu-open")}}>Home</li>
                <InternalLink 
                    to="about"
                    spy={true}
                    smooth={true}
                    offset={-50}
                    duration={500}
                >
                    <li onClick={() => props.changeMenu("menu-open")}>Quem somos?</li>
                </InternalLink>

                <InternalLink 
                    to="serviços"
                    spy={true}
                    smooth={true}
                    offset={-50}
                    duration={500}
                >
                    <li onClick={() => props.changeMenu("menu-open")}>Serviços</li>
                </InternalLink>

                <InternalLink 
                    to="treinamentos"
                    spy={true}
                    smooth={true}
                    offset={-50}
                    duration={500}
                >
                    <li onClick={() => props.changeMenu("menu-open")}>Treinamentos</li>
                </InternalLink>
            </Header> 
            */}   

            <SectionTop>
                <Logo />
                <h1>Capacitação <br /> Consultoria <span>&</span> Serviços</h1>
                {/** <h4>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </h4> */}
                <Link  to="/contato">
                    <Button onClick={() => Change("contact")}>Entre em contato</Button>
                </Link>
            </SectionTop>
            
            <Section id="section-clients">
                <img src={ImgClients} alt="Nossos clients"/>
            </Section>

            <span name="about" href="#" />
            <Section id="section-about">                
                <h3>Segurança do Trabalho, Medicina Ocupacional, Meio Ambiente e Serviços.</h3>
                
                <p>Somos uma empresa formada por profissionais associados com larga experiência, 
                    em <b>Industrias Mecânicas, Químicas, Farmacêuticas, Mineradoras, Construtoras, 
                    Shopping Centers, Hospitais,</b> tonando a <b>Cretta <span>CCS</span></b> uma empresa que oferece 
                    mais que soluções!</p>
                
                {/** 
                <Link to="/em-construção">
                    <h6 onClick={() => Change("about")}>Saiba mais</h6>
                </Link>
                */}
            </Section>          

            <span name="serviços" href="#" />
            <SectionGrid 
                title="Nossos serviços"
                subtitle="Capacitação, Consultoria e Serviços"
                className="services"
            >
                <CardServices
                    src={ImgLifeline}
                    text="Instalação de postos de ancoragem e linhas de vida"
                />

                <CardServices
                    src={ImgAlarms}
                    text="Instalação de Sistema de Incêndio"
                />  
                <CardServices
                    src={ImgRisk}
                    text="Acompanhamento de Atividades de Risco"
                />

                <CardServices
                    src={ImgSpecialized}
                    text="Fornecimento de mão de obra especializada"
                />  
                
                <CardServices
                    src={ImgEnvironment}
                    text="Levantamento de Aspectos e Impactos Ambientais"
                />

                <CardServices
                    src={ImgSSMA}
                    text="Implantação de Sistema de Gestão SSMA"
                />

                <CardServices
                    src={ImgRiskMagenement}
                    text="Programa de gerenciamento de risco"
                />  

                <CardServices
                    src={ImgProjects}
                    text="Civis, Hidráulicos, Elétricos e TI"
                />  

                <CardServices
                    src={ImgMedicalExams}
                    text="Exames médicos ocupacionais"
                />                
            </SectionGrid>

            <span  name="treinamentos" href="#" />
            <Section id="section-trainings">
                <div id="section-trainings-content">
                    <div id="blue-box" />

                    <div id="white-box">
                        <h3>Consultoria e capacitação profissional</h3>
                        <p>
                            A Cretta CCS é especializada na capacitação de profissionais através de treinamentos de 
                            formação e reciclagem bem como na consultoria em Segurança do Trabalho, Medicina Ocupacional, Meio Ambiente, Constução Civil e TI.
                            Formada por profissionais associados com larga experiência, em Industrias Mecânicas, Químicas, 
                            Farmacêuticas, Mineradoras, Construtoras, Shopping Centers, Hospitais, tonando a Cretta CCS empresa que 
                            oferece soluções seguras e confiáveis na prestação dos serviços.
                        </p>
                        <div id="buttons">
                            <Link to="/consultoria"><button onClick={() => Change("consulting")}>Consultoria</button></Link>
                            <Link to="/capacitação"><button onClick={() => Change("training")}>Capacitação</button></Link>
                        </div>
                    </div>
                </div>
            </Section>

            <SectionContactFooter />

            <Footer />
        </>
    )
}

function mapStateToProps(state){
    return{
        currentPage: state.currentPage
    }
}

function mapActionCreatorsToProps(dispatch){
    return{
        changePage(newPage){
            const action = ChangePage(newPage)
            dispatch(action)
        }
    }
}

export default connect(
    mapStateToProps,
    mapActionCreatorsToProps
)(Home)

import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import './styles.scss'

export default (props) => (
  <Carousel infiniteLoop>
    {props.children}
  </Carousel>
);

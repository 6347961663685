import React from 'react'

import Section from '../Section'
import Form from '../Form'
import ImgTools from '../../assets/images/tools.png'

import './styles.scss'

export default function SectionContactFooter(){
    return(
        <>
            <Section className="section-contact">
                <h2>Nós ligamos para você!</h2>
                <p>Gostaria que entrassemos em contato com você? Informe seu contato e o melhor horário para falarmos.</p>
            </Section>
            <Section className="section-form">
                <img src={ImgTools} alt="Ferramentas de trabalho, capacete, óculos, abafador e botas"/>
                <Form />                
            </Section>
        </>
    )
}
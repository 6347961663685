import React from 'react'

import Section from '../../components/Section'
import Form from '../../components/Form'

import Footer from '../../components/Footer'

import './styles.scss'

export default function Contact(){
    return(
        <>
            {/**
            <Header>
                <Link to="/"><li>Home</li></Link>
                <li>Quem somos?</li>
                <li>Serviços</li>
                <li>Treinamentos</li>
            </Header> 
             */}
            
            <Section id="section-contact-form">
                <h1>Nós <br/><span>ligamos</span><br/> para você!</h1>
                <Form buttonBack={true}/>
            </Section>

            <Footer className="footer-contact-page"/>
        </>
    )
}
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import App from './App'
import ScrollToTop from './components/ScrollToTop'
import Home from './pages/Home'
import Contact from './pages/Contact'
import Consulting from './pages/Consulting'
import Training from './pages/TrainingOnline'
import TrainingPresential from './pages/TrainingPresential'
//import Construct from './pages/Construct'
import EmailSuccess from './pages/EmailSuccess'
import PageNotFound from './pages/PageNotFound'

function Routes() {
  return (    
    <Router>
      <ScrollToTop />
      <App>
        <Switch>
          <Route exact path="/" component={Home}/>
          <Route exact path="/contato" component={Contact}/>
          <Route path="/consultoria" component={Consulting}/>
          <Route path="/capacitação-online" component={Training}/>
          <Route path="/capacitação-presencial" component={TrainingPresential}/>
          {/** <Route path="/em-construção" component={Construct} /> */}
          <Route path="/contato/email-enviado-com-sucesso" component={EmailSuccess} />
          <Route component={PageNotFound} />
        </Switch> 
      </App>
    </Router>
  );
}

export default Routes;


/**
 * 
 * Jaapokki Regular*
 */
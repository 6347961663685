import React from 'react'
import { connect } from 'react-redux'
import ChangeState from '../../store/actions/changeMenuMobile'

import './styles.scss'

function MenuMobileIcon(props){
    const { menuState } = props.menuState

    return(
        <div className={`menu-mobile-icon ${menuState}`} onClick={()=>props.changeMenu(menuState)}>
            { 
                props.className === "menu-close" 
                    ?<svg 
                        stroke="currentColor" 
                        fill="none" 
                        stroke-width="2" 
                        viewBox="0 0 24 24" 
                        stroke-linecap="round" 
                        stroke-linejoin="round" 
                        height="24px" 
                        width="24px" 
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <line x1="3" y1="12" x2="21" y2="12"></line>
                        <line x1="3" y1="6" x2="21" y2="6"></line>
                        <line x1="3" y1="18" x2="21" y2="18"></line>
                    </svg>
                    :
                    <svg 
                        stroke="currentColor" 
                        fill="currentColor" 
                        stroke-width="0" 
                        viewBox="0 0 512 512" 
                        height="24px" 
                        width="24px" 
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M405 136.798L375.202 107 256 226.202 136.798 107 107 136.798 226.202 256 107 375.202 136.798 405 256 285.798 375.202 405 405 375.202 285.798 256z"></path>
                    </svg>
                }
        </div>
    )
}

function mapStateToProps(state){
    return{
        menuState: state.menuState,
    }
}

function mapActionCreatorsToProps(dispatch){
    return{
        changeMenu(currentState){
            const action = ChangeState(currentState)
            dispatch(action)
        }
    }
}

export default connect(
    mapStateToProps, 
    mapActionCreatorsToProps
)(MenuMobileIcon)
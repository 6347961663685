import React from 'react'

export default function LogoSmall(props){
    return(
        
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="51" height="43" viewBox="0 0 51 43" {...props}>
            <defs>
                <filter id="gvmisoeojb" width="109.2%" height="109.3%" x="-4.6%" y="-4.7%" filterUnits="objectBoundingBox">
                    <feGaussianBlur in="SourceAlpha" result="shadowBlurInner1" stdDeviation=".5"/>
                    <feOffset dy="1" in="shadowBlurInner1" result="shadowOffsetInner1"/>
                    <feComposite in="shadowOffsetInner1" in2="SourceAlpha" k2="-1" k3="1" operator="arithmetic" result="shadowInnerInner1"/>
                    <feColorMatrix in="shadowInnerInner1" values="0 0 0 0 0.257246377 0 0 0 0 0.257246377 0 0 0 0 0.257246377 0 0 0 0 0"/>
                </filter>
                <path id="d22yo1lyma" d="M25.182 15.728c5.84 0 10.596 4.609 10.786 10.365h-6.845c-.182-2.003-1.877-3.574-3.941-3.574-2.186 0-3.957 1.76-3.957 3.932 0 2.172 1.771 3.932 3.957 3.932 2.064 0 3.759-1.57 3.94-3.574h6.846c-.19 5.756-4.946 10.365-10.786 10.365-5.96 0-10.792-4.8-10.792-10.723 0-5.922 4.831-10.723 10.792-10.723z"/>
            </defs>
            <g fill="none" fill-rule="evenodd" transform="translate(0 .213)">
                <path fill="#1491FF" d="M27.508 3.83l20.345 33.5c.78 1.284.371 2.958-.914 3.738-.426.26-.914.396-1.412.396H4.837c-1.503 0-2.722-1.219-2.722-2.722 0-.498.137-.987.396-1.413L22.856 3.83c.78-1.284 2.454-1.693 3.739-.913.373.227.686.54.913.913z"/>
                <use fill="#FFF" xlinkHref="#d22yo1lyma"/>
                <use fill="#000" filter="url(#gvmisoeojb)" xlinkHref="#d22yo1lyma"/>
            </g>
        </svg>    

    )
}
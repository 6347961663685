import React from 'react'
import { Link } from 'react-router-dom'

import Logo from '../Logo'
import Section from '../Section'
import SocialMedia from '../SocialMedia'

import './styles.scss'

export default function Footer(props){
    return(
        <>
            <Section className={`footer ${props.className}`} >
                <Logo />
                <ul >                        
                    <li className="li-header">Menu</li>
                    <li className="li-menu"> 
                        <ul>
                            <Link to="/">
                                <li>Home</li>
                            </Link>

                            {/** 
                            <Link to="/em-construção">
                                <li>Quem somos?</li>
                            </Link>   
                            */}                     
                            <Link to="/consultoria">
                                <li>Consultoria</li>
                            </Link>
                        </ul>
                        <ul>
                            <Link to="/capacitação-online">
                                <li>Capacitação Online</li>
                            </Link>
                            <Link to="/contato">
                                <li>Contato</li>
                            </Link>
                        </ul>
                    </li>
                </ul>

                <ul>
                    <li className="li-header">Contato</li>
                    <li>Av. Luiz Carmem, 409, Pinheiros - SP</li>
                    <li>Telefone: (11) 93043-9309</li>
                    <li>Email: cretta@atendimento.com.br</li>
                </ul>

                <ul>
                    <li class="li-header">Redes Sociais</li>
                    <li><SocialMedia /></li>
                </ul>

            </Section>

            <Section className={`footer-copyright ${props.className}`} >
                <p>Cretta © Todos os direitos reservados. 2020. Desenvolvido por K-Monster</p>
            </Section>

        </>
    )
}
import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import ChangeModal from '../../store/actions/changeModal'
import ChangeModalContent from '../../store/actions/changeModalContent'
import Form from '../Form'
import Logo from '../Logo'
import WhiteSpace from '../WhiteSpace'
import Button from '../Button'

import './styles.scss'

function ModalCourses(props){   
    const modal = props

    console.log(modal.content.modalContent, modal.modal.modalState)   
 
    if( modal.content.modalContent === 'contact-form' ){
        
        return(        
            <div className={`modal-background ${modal.modal.modalState}`} >
                <div className="modal">
                    <h1>Nós <span>ligamos</span> para você!</h1>
                    <Form>
                        <span className="cancel-button" onClick={() => props.changeModal("modal-close")}>Cancelar</span>
                    </Form>                
                </div>            
            </div>
        )
    }else{
        return(
            <div className={`modal-background ${modal.modal.modalState}`} >
                <div className="modal">
                    <Logo />
                    <h1>Contato enviado com <span>sucesso!</span></h1>
                    <h4>Em breve entraremos em contato!</h4>

                    <WhiteSpace height="60" />
                    <Link  to="/">
                        <Button className="primary">Voltar para a home</Button>
                    </Link>
                </div>            
            </div>
        )
    }
}

function mapStateToProps(state){
    return{
        modal: state.modalState,
        content: state.modalContent
    }
}

function mapActionCreatorsToProps(dispatch){
    return{
        changeModal(newModal){
            const action = ChangeModal(newModal)
            dispatch(action)
        },
        changeModalContent(newModalContent){
            const action = ChangeModalContent(newModalContent)
            dispatch(action)
        },
    }
}

export default connect(
    mapStateToProps,
    mapActionCreatorsToProps
)(ModalCourses)



import React from 'react';
import Header from './components/Header'

import './styles/global.scss' 

export default function App(props) {
  return (
    <>
      <Header />
      {props.children}           
    </>
  )
}


import React from 'react'

export default function InputGroup({label, feedback, ...props}){
    return(
        <>
            <label for={props.id}>
                {label} &nbsp;

                <span className={ feedback==='' || "feedback"}>
                    {feedback==='' ? '*' : feedback }
                </span>

            </label>
            <input {...props} name={`user_${props.id}`}/>
        </>
    )
}